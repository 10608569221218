import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
//import { StaticImage } from "gatsby-plugin-image"
//import Logo from "../images/black-circle-ill.svg";
// import Logo from "../images/svg/oval.svg";
// import logo from '../images/svg/humaan-logo-light.svg';


const createTagList = (tags) =>{
  const myClassNames = 'tag tag-blog';
    const listItems = tags.map((tag) =>
      <li key={tag.toString()} className={myClassNames}>
        
        {tag}
      </li>
    );
    return (
      <ul className="blog-tags">{listItems}</ul>
    );
}


const Blogitem = ({ blogTitle, blogDate, blogSlug, blogAuthor, blogAuthorImage, blogExcerpt, blogTags }) => (
  
  
      <div className="blog-card">

<span className="small-print">{blogDate}</span>
       

        
        <Link key={blogSlug} to={`/post/${blogSlug}`}>
        <h3>{blogTitle}</h3>
        <p>{blogExcerpt}</p>
      </Link>

{createTagList(blogTags)}

       

        
       
        
          {/* <img src={blogAuthorImage} className="" alt="author"/>
          <small className="pl-4">{blogAuthor}</small> */}
     
      </div>
      
    
 
)

Blogitem.propTypes = {
  blogTitle: PropTypes.string,
}

Blogitem.defaultProps = {
  blogTitle: ``,
}

export default Blogitem
