import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Blogitem from "../components/blogitem"
import person from "../images/svg/person-chat.svg"

// const pageQuery = graphql`
//   {
//     gcms {
//       posts {
//         slug
//         title
//         date
//         tags
//         excerpt
//         author {
//           name
//           picture {
//             url(transformation: {image: {}})
//             fileName
//           }
//         }
//       }
//     }
//   }
// `;
// const pageQuery = graphql`
//    {
//     allGraphCmsPost {
//       nodes {
//         id
//         title
//         slug
//         tags
//         content {
//           markdownNode {
//             childMdx {
//               body
//             }
//           }
//         }
//       }
//     }
//   }

// `;



const BlogPage = (data) => {
  //  const {posts} = useStaticQuery(pageQuery)
   const { posts } = useStaticQuery(graphql`
    {
      posts: allGraphCmsPost {
        nodes {
          id
          title
          slug
          excerpt
          tags
          content {
            markdownNode {
              childMdx {
                body
              }
            }
          }
          author {
            name
            picture {
              authorAvatar {
                picture {
                  fileName
                  url
                }
              }
            }
          }
        }
        
      }
    }
  `)

  return (
    <Layout>
      <Seo title="Home" />
      <div>
        <div class="page-header">
          <div class="header-container">


            <div class="header-copy">
              <h1>Blog</h1>
              <p>Things that I’m learning, thinking about and doing. Coming soon...
                </p>
            </div>
            <div class="header-image">
              <img src={person} alt="Person worried" style={{ width: "180px", margin: "0 auto" }} />
            </div>
          </div>
        </div>
        <div >

          {/* <ul className="blog-post-list">

{console.log(posts)}
            {posts.nodes.map(({ ...post }) => (
              <li> */}
{/* {post.title} {post.id} {post.slug} */}
{/* blogAuthor={post.author.name} blogAuthorImage={post.author.picture.url} */}
                {/* <Blogitem blogTitle={post.title} blogDate={post.date} blogSlug={post.slug}  blogExcerpt={post.excerpt} blogTags={post.tags}>
                

                </Blogitem> */}
              {/* </li>
            ))}
          </ul> */}
        </div>

      </div>
    </Layout>
  );

};



export default BlogPage;
